import React, { useState, useEffect, Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes, Navigate, Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "./features/auth/auth.action";
import Loader from './components/Loader'

const Home = lazy(() => import("./pages/Home/Home"))
const WhatsNew = lazy(() => import("./pages/WhatsNew"))
const SignUp = lazy(() => import("./pages/SignUp/SignUp"))
const Login = lazy(() => import("./pages/Login/Login"))
const Receipt = lazy(() => import("./pages/Receipt/Receipt"))
const Settings = lazy(() => import("./pages/Settings/Settings"))
const Verfication = lazy(() => import("./pages/Verfication/Verfication"))
const ForgetPassword = lazy(() => import("./pages/ForgetPassword/ForgetPassword"))
const ProductCollection = lazy(() => import("./pages/ProductCollections/ProductCollection"))
const ResetPassword = lazy(() => import("./pages/ResetPassword/ResetPassword"))
const MapComponent = lazy(() => import("./pages/Map/MapComponent"))
const Island = lazy(() => import("./pages/Island/Island"))
const Profile = lazy(() => import("./pages/Profile/Profile"))
const Catalogue = lazy(() => import("./pages/Catalogue/Catalogue"))
const Payment = lazy(() => import("./pages/Catalogue/Payment"))
const Checkout = lazy(() => import("./pages/Catalogue/CheckoutPage"))
const Tweets = lazy(() => import("./pages/Tweets/Tweets"))
const PaymentVerification = lazy(() => import("./pages/PaymentVerification/PaymentVerification"))
const Directory = lazy(() => import("./pages/Directory/Directory"))
const IslandProfile = lazy(() => import("./pages/IslandProfile/IslandProfile"))
const UserIslandProfile = lazy(() => import("./pages/UserIslandProfile/UserIslandProfile"))
const Message = lazy(() => import("./pages/Settings/Message"))
const Account = lazy(() => import("./pages/Account/Account"))
const TermsAndConditions = lazy(() => import("./pages/TermsAndCondition"))
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"))
const UnderConstruction = lazy(() => import("./pages/UnderConstruction/UnderConstruction"))
const StarChart = lazy(() => import("./pages/StarChart"))
const DoubleStarChartCreate = lazy(() => import("./pages/DoubleStarChartCreate"))
const DoubleStarChartView = lazy(() => import("./pages/DoubleStarChartView"))
const UserStarChart = lazy(() => import("./pages/UserStarChart"))
const UserDoubleStarChart = lazy(() => import("./pages/UserDoubleStarChart"))
const AboutUs = lazy(() => import("./pages/AboutUs"))
const AboutUsStarchart = lazy(() => import("./pages/AboutUsStarchart"))
const CNSSkeleton = lazy(() => import("./pages/CNSSkeleton"))
const Corvus = lazy(() => import("./pages/Corvus"))
const PNSOrion = lazy(() => import("./pages/PNSOrion"))
const NewPage = lazy(() => import("./pages/NewPage"))
const CNS = lazy(() => import("./pages/NervousSystem/CNS"))
const PNS = lazy(() => import("./pages/NervousSystem/PNS"))

// import VideoComponent from "./pages/VideoComponent/VideoComponent";


const MainRoutes = (props) => {
  const dispatch = useDispatch();
  const keepLoggedIn = useSelector((state) => state.auth.keepLoggedIn);
  const authenticated = useSelector((state) => state.auth.authenticated);
  const protectedRoutes = useSelector((state) => state.auth.protectedRoutes);


  if (!keepLoggedIn) {
    const sessionToken = sessionStorage.getItem('token');
    if (!sessionToken) {
      dispatch(logout());
    }
  }

  const PublicRoute = () => {
    if (authenticated) {
      return <Navigate to="/map" />;
    }

    return <Outlet />;
  };

  const PrivateRoute = () => {
    if (!authenticated) {
      return <Navigate to="/login" />;
    }

    return <Outlet />;
  };

  const ProtectedRoutes = () => {
    if (!authenticated) {
        return <Navigate to="/login" />;
    }
    else if (!protectedRoutes.singleStarcharts) {
      return <Navigate to="/map" />;
    }

    return <Outlet />;
  };

  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/verfiyaccount" element={<Verfication />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/whatsnew" element={<WhatsNew />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/aboutstarchart" element={<AboutUsStarchart />} />
          {/* <Route path="/interroutesconnecting" element={<VideoComponent />} /> */}
          <Route path="/underconstruction" element={<UnderConstruction />} />

          <Route path="/" element={<PublicRoute />}>
            <Route path="/" element={<Home />} />
            <Route path="/forgetpassword" element={<ForgetPassword typeWriterSound={props.typeWriterSound} />} />
            <Route path="/reset_password/:token" element={<ResetPassword typeWriterSound={props.typeWriterSound} />} />
            <Route path="/signup" element={<SignUp typeWriterSound={props.typeWriterSound} />} />
            <Route path="/login" element={<Login typeWriterSound={props.typeWriterSound} />} />
          </Route>

          <Route path="/" element={<ProtectedRoutes />}>
            <Route path="/starchart" element={<StarChart />} />
            <Route path="/doublestarchart-create" element={<DoubleStarChartCreate />} />
            <Route path="/doublestarchart-view" element={<DoubleStarChartView />} />
          </Route>

          <Route path="/" element={<PrivateRoute />}>
            <Route path="/message" element={<Message />} />
            <Route path="/tweets" element={<Tweets />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/upload" element={<Profile />} />
            <Route path="/catalog" element={<Catalogue />} />
            <Route path="/island" element={<Island />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/receipt" element={<Receipt />} />
            <Route path="/collection" element={<ProductCollection />} />
            <Route path="/islandprofile" element={<IslandProfile />} />
            <Route path="/userislandprofile/:id" element={<UserIslandProfile />} />
            <Route path="/map" element={<MapComponent />} />
            <Route path="/directory" element={<Directory />} />
            <Route path="/user-starchart-single/:id" element={<UserStarChart />} />
            <Route path="/user-starchart-double/:id" element={<UserDoubleStarChart />} />
            <Route path="/cns-skeleton" element={<CNSSkeleton />} />
            <Route path="/corvus" element={<Corvus />} />
            <Route path="/pns-orion" element={<PNSOrion />} />
            <Route path="/new-page" element={<NewPage />} />
            <Route path="/paymentverification/:status" element={<PaymentVerification />} />
            <Route path="/account" element={<Account />} />
            <Route path="/pns" element={<PNS />} />
            <Route path="/cns" element={<CNS />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter >
  );
}

export default MainRoutes;
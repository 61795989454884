
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  cart: [],
  shipmentAddress: ''
};
const cartSlice = createSlice({
  name: "cartService",
  initialState,
  reducers: {
    getLoading: (state) => {
      state.loading = true;
    },
    addToCart: (state, { payload }) => {
      const item = state.cart.find((itm) => itm._id === payload._id);
      state.loading = false;
      if (item) {
        item.qty += payload.qty;
      } else {
        state.cart = [...state.cart, payload];
      }
    },
    addShipmentAddress: (state, { payload }) => {
      state.shipmentAddress = payload;
    },
    empty: (state, { payload }) => {
      state.cart = [];
    },
    increaseQtyToCart: (state, { payload }) => {
      const item = state.cart.find((itm) => itm._id === payload._id);
      if (item) {
        item.qty = item.qty + 1;
      }
    },
    decreaseQtyToCart: (state, { payload }) => {
      const item = state.cart.find((itm) => itm._id === payload._id);
      if (item) {
        item.qty = item.qty - 1;
        if (item.qty === 0) {
          let orders = state.cart.filter(
            (item) => item._id !== payload._id
          );
          state.loading = false;
          state.cart = [...orders];
        }
      }
    },
    removeFromCart: (state, { payload }) => {
      let orders = state.cart.filter(
        (item) => item._id !== payload._id
      );
      state.loading = false;
      state.cart = [...orders];
    },
  },
});
const cartReducer = cartSlice.reducer;
export default cartReducer;

export const {
  getLoading,
  addToCart,
  removeFromCart,
  decreaseQtyToCart,
  increaseQtyToCart,
  empty,
  addShipmentAddress
} = cartSlice.actions;
